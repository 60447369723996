<template>
  <section id="config-ai">
    <b-tabs @input="changeAgent">
      <b-tab v-for="(agent, index) in agents" :key="index" :title="agent.name">
        <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-row>
            <b-col xl="6">
              <b-card>
                <b-card-header>
                  <h4 class="mb-0">
                    Agent
                    <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-agent" />
                  </h4>
                  <b-popover target="popover-agent" triggers="hover" placement="bottom">
                    <span>No data</span>
                  </b-popover>
                </b-card-header>
                <b-form @submit.prevent="onAgentFormSubmit(index)">
                  <b-form-group label="ChatGPT Model" label-for="chat-gpt-model">
                    <b-form-input id="chat-gpt-model" v-model="agent.chat_gpt_model" />
                  </b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn" type="submit">
                    Update Model
                  </b-button>
                </b-form>
              </b-card>
            </b-col>
            <b-col xl="6">
              <b-card>
                <b-card-header>
                  <h4 class="mb-0">
                    Context windows
                    <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                      id="popover-context-windows" />
                  </h4>
                  <b-popover target="popover-context-windows" triggers="hover" placement="bottom">
                    <span>No data</span>
                  </b-popover>
                </b-card-header>
                <b-form @submit.prevent="onContextWindowsFormSubmit">
                  <b-form-group v-for="(contextWindow, index2) in agent.context_windows" :key="index2"
                    :label="contextWindow.name" label-for="contextWindow">
                    <b-form-textarea id="contextWindow" v-model="contextWindow.content" rows="6" />
                  </b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn" type="submit">
                    Update Context Windows
                  </b-button>
                </b-form>
              </b-card>
            </b-col>
          </b-row>
          <b-card>
            <b-card-header>
              <h4 class="mb-0">
                Knowledge base
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-knowledge-base" />
              </h4>
              <b-popover target="popover-knowledge-base" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <b-row>
              <b-col xl="6">
                <b-form @submit.prevent="onUploadedFileSubmit">
                  <b-form-group label="Uploaded File" label-for="uploaded-file">
                    <b-form-file id="uploaded-file" v-model="uploadedFile"
                      placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                  </b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn" type="submit">
                    Upload File
                  </b-button>
                </b-form>
              </b-col>
              <b-col xl="6">
                <h5>Uploaded Files</h5>
                <b-badge v-for="(uploaded_file, index2) in agent.uploaded_files" :key="index2" class="uploaded-files"
                  variant="primary">
                  {{ uploaded_file.name }}
                </b-badge>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col xl="6">
                <b-form @submit.prevent="onAddOnloneFileSubmit">
                  <b-form-group label="Online File" label-for="online-file">
                    <b-form-input id="chat-gpt-model" v-model="onlineFile" placeholder="URL" />
                  </b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn" type="submit">
                    Add Online File
                  </b-button>
                </b-form>
              </b-col>
              <b-col xl="6">
                <h5>Online Files</h5>
                <b-badge v-for="(online_file, index2) in agent.online_files" :key="index2" class="uploaded-files"
                  variant="primary">
                  {{ online_file.title }}
                </b-badge>
              </b-col>
            </b-row>
          </b-card>
          <div v-if="agent.name == 'Customer Support Assistant'">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Chat messages
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-chat-messages" />
                </h4>
                <b-popover target="popover-chat-messages" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <b-table striped hover responsive class="position-relative" :current-page="chatMessagesTable.currentPage"
                :items="chatMessagesTable.items" :fields="chatMessagesTable.fields"
                :sort-by.sync="chatMessagesTable.sortBy" :sort-desc.sync="chatMessagesTable.sortDesc"
                :sort-direction="chatMessagesTable.sortDirection" :filter="chatMessagesTable.filter"
                :filter-included-fields="chatMessagesTable.filterOn" />
              <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- pagination -->
                <div>
                  <b-pagination v-model="chatMessagesTable.currentPage" :total-rows="chatMessagesTable.totalRows"
                    first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                    @change="handleChatMessagesTablePageChange">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-overlay>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCard,
  BCardBody,
  BCardHeader,
  BPopover,
  BModal,
  BOverlay,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BTab,
  BTabs,
  BFormFile,
  BBadge,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BModal,
    BOverlay,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTab,
    BTabs,
    BFormFile,
    BBadge,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: true,
      agents: [],
      selectedAgent: null,
      uploadedFile: [],
      onlineFile: "",
      chatMessagesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'created',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'full_name', label: 'user', sortable: true },
          { key: 'question', label: 'question', sortable: true },
          { key: 'response', label: 'response', sortable: true },
          {
            key: 'created',
            label: 'created',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      queryParamsChatMessages: {},
    }
  },
  async created() {
    await this.getAgents();
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAgents() {
      this.show = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/agents/`, {});
        this.agents = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          this.showToast('warning', error);
        }
      }
      finally {
        this.show = false;
      }
    },
    async getChatMessages() {
      try {
        this.queryParamsChatMessages.agent_id = this.selectedAgent.id;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/chat-messages/`, this.queryParamsChatMessages);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.chatMessagesTable.items = results;
          this.chatMessagesTable.totalRows = response.data.count * 2;
        }
        else {
          this.chatMessagesTable.items = [];
          this.chatMessagesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          this.showToast('warning', error);
        }
      }
    },
    async changeAgent(index) {
      this.selectedAgent = this.agents[index];
      await this.getChatMessages();
    },
    async handleChatMessagesTablePageChange(value) {
      this.queryParamsChatMessages.page = value;
      await this.getChatMessages();
    },
    async onAgentFormSubmit(index) {
      try {
        const response = await axios.patch(`${process.env.VUE_APP_ROOT_API}/agent/update/${this.selectedAgent.id}/`, {
          chat_gpt_model: this.agents[index].chat_gpt_model
        }, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        });
        console.log(response);
        this.showToast('success', 'Agent has been updated');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          this.showToast('warning', error);
        }
      }
    },
    async onContextWindowsFormSubmit() {
      for (const contextWindow of this.selectedAgent.context_windows) {
        try {
          const response = await axios.patch(`${process.env.VUE_APP_ROOT_API}/context-window/update/${contextWindow.id}/`, {
            content: contextWindow.content
          }, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          });
          console.log(response);
          this.showToast('success', 'Context windows have been updated');
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'login' });
          } else {
            // Handle other types of errors
            this.showToast('warning', error);
          }
        }
      }
    },
    async onUploadedFileSubmit() {
      const formData = new FormData();
      formData.append('file', this.uploadedFile);
      formData.append('agent', this.selectedAgent.id);
      try {
        const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/file/upload/`, formData, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        });
        console.log(response);
        this.showToast('success', 'File has been uploaded');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          this.showToast('warning', error);
        }
      } finally {
        await this.getAgents();
      }
    },
    async onAddOnloneFileSubmit() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/online-file/add/`,
          {
            title: "empty",
            url: this.onlineFile,
            agent: this.selectedAgent.id,
          }, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        });
        console.log(response);
        this.showToast('success', 'File has been added');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          this.showToast('warning', error.response.data.message);
        }
      } finally {
        await this.getAgents();
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.uploaded-files {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}
</style>